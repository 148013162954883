export default class TabWorkBg {
  constructor(link1, link2, background) {
    this.link1 = document.querySelectorAll(link1)
    this.link2 = document.querySelectorAll(link2)
    this.workBg = document.querySelectorAll(background)
    this.activeClass = 'ativo';
  }

  // Tab Menu - Background
  activeFirstLink() {
    this.workBg.forEach((div) =>{
      div.classList.add('how-work-1')
      div.classList.remove('how-work-2')
      div.classList.remove('how-work-3')
    });
  }
  activeSecondLink() {
    this.workBg.forEach((div) =>{
      div.classList.remove('how-work-1')
      div.classList.add('how-work-2')
      div.classList.remove('how-work-3')
    });
  }

    // Adiciona os eventos nas tabs
    addTabBg(){
      this.link2.forEach((firstWork, index) => {
        firstWork.addEventListener('mouseover', () => this.activeSecondLink(index))
      })

      this.link1.forEach((firstWork, index,) => {
        firstWork.addEventListener('mouseover', () => this.activeFirstLink(index))
      })
    }

// Inicia os eventos
    init() {
      if (this.link1.length && this.link2.length && this.workBg.length) {
        this.activeFirstLink();
        this.addTabBg();
      }
    }
  }

