import Utils from "./utils.class";
const { jQuery: $ } = window;

export default class CMP {
    #debug;

    #body;

    #events = {
        ready: "kmdg_cmp_ready",
        consent: {},
        denied: {}
    }

    constructor($body, readyCB = ()=>true, debug = false) {
        Utils.log("Registered: CMP Integration");
        this.#debug = debug;
        this.#body = $body;

        for(let i in this.types) {
            this.#events.consent[this.types[i]] = `kmdg_cmp_${this.types[i]}_consent`;
            this.#events.denied[this.types[i]] = `kmdg_cmp_no_${this.types[i]}_consent`;
        }

        // Set up basic response to consent events
        for(let i in this.types) {
            const category = this.types[i];

            Utils.log(`[CMP] Consent Event Registered: ${category} (${this.events.consent[category]}, ${this.events.denied[category]})`);

            $(window).on(this.events.consent[category], function() {
                Utils.log(`Have ${category} consent`);

                $body.removeClass(`cmp--denied-${category}`);
                $body.removeClass(`cmp--loading`);
                $body.addClass(`cmp--consent-${category}`);
            });

            $(window).on(this.events.denied[category], function() {
                Utils.log(`No ${category} consent`);

                $body.removeClass(`cmp--consent-${category}`);
                $body.removeClass(`cmp--loading`);
                $body.addClass(`cmp--denied-${category}`);
            });
        }

        $(window).on(this.events.ready, () => {
            Utils.log("Consent API Ready");
        });

        $(document).on('click', ".js--open-cmp", () => {
            // Redirects a click on our custom button to the actual cookie link (since that seems to be the only way to open the popup)
            this.open();
        });

        $(() => {
            // Wait for API to be ready then start triggering events.
            if(typeof readyCB === "string") {
                window.addEventListener(readyCB, () => {
                    this.load();
                    $(window).trigger(this.events.ready);
                });
            } else if(!readyCB()) {
                const CMPWatcher = setInterval(() => {
                    if(readyCB) {
                        clearInterval(CMPWatcher);
                        this.load();
                        $(window).trigger(this.events.ready);
                    }
                }, 100);
            } else {
                setTimeout(() => {
                    this.load();
                    $(window).trigger(this.events.ready);
                }, 1);
            }
        });
    }

    load() {
        // Override
        Utils.log("Load CMP method not implemented.");
    }

    open() {
        // Override
        Utils.log("Open CMP method not implemented.");
    }

    hasConsent(category) {
        Utils.log("hasConsent CMP method not implemented.");
        return true; // allows for testing without a CMP
    }

    get events() {
        return this.#events;
    }

    get types() {
        return ["functional", "preferences", "marketing", "statistics", "necessary"];
    }

    get debug() {
        return this.#debug;
    }

    get $body() {
        return this.#body;
    }
}