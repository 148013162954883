export default class TabIntegrations {
  constructor(list, icons) {
    this.tabList = document.querySelectorAll(list);
    this.tabIcons = document.querySelectorAll(icons);
    this.activeClass = 'ativo';
  }


  // Tab Menu - Solving Leads
  activeIcons(index) {
    this.tabIcons.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabIcons[index].classList.add(this.activeClass);
  }
  activeList(index) {
    this.tabList.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabList[index].classList.add(this.activeClass);
  } 
    // Adiciona os eventos nas tabs
    addTabNavEvent(){
      this.tabList.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeList(index))
      })

      this.tabList.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeIcons(index))
      })
    }

// Inicia os eventos
    init() { 
      if (this.tabList.length && this.tabIcons.length) {
        this.activeList(0);
        this.activeIcons(0);
        this.addTabNavEvent(0);
      }
    }
  }

