const { jQuery: $, themeSettings: config } = window;
import ThemeClass from './modules/theme.class';
import Utils from "./modules/utils.class";
import SlickSlider from "./modules/slickslider.class";

require("./lib/slick");
import Slick from "imports-loader?imports=default|jquery|$!./lib/slick";

import TabSolving from './modules/tab-solving.js';
import TabSolution from './modules/tab-solutions.js';
import TabWork from './modules/tab-work.js';
import TabWorkBg from './modules/tab-work-bg.js';
import TabIntegrations from './modules/tab-integrations.js';
import initOutsideClick from './modules/outsideclick.js';
import Accordion from './modules/accordion.js';
import TabPartner from './modules/tab-partner.js';
import TabTestimonial from './modules/tab-testimonial.js';
import Modal from './modules/modal.js';
import ModalMobile from './modules/modal-mobile.js';
import ModalMobileSolution from './modules/modal-solution.js';
import ModalWhy from './modules/modal-why.js';
import ModalIndustries from './modules/modal-industries.js';
import ModalCompany from './modules/modal-company.js';
import ModalResources from './modules/modal-resources.js';
import ModalCeo from './modules/modal-ceo.js';
import ModalCoo from './modules/modal-coo.js';
import ModalCpo from './modules/modal-cpo.js';
import ModalMdp from './modules/modal-mdp.js';
import ModalSdc from './modules/modal-sdc.js';
import ModalDoe from './modules/modal-doe.js';
import ModalAbout from './modules/modal-about.js';
import ModalCookies from './modules/modal-cookies.js';
// import initcookies from './modules/cookies.js';



// Tabs
const tabSolving = new TabSolving('.js-tabmenu div.tab-menu', '.js-tabcontent div.content-solving');
const tabSolution = new TabSolution('.js-solution div.img-solution', '.js-card div.card-our-solution');
const tabWork = new TabWork('.js-info-work div.info-how-work', '.js-content-work div.info-graphic', '.js-links div.link', '.js-work-links div.work-link');
const tabWorkBg = new TabWorkBg('.js-links div.link-1', '.js-links div.link-2', '.js-how-work div.content-how-work');
const tabIntegrations = new TabIntegrations('.js-integrations li p', '.js-logos-integrations .container-logos');
const tabPartner = new TabPartner('.js-text-partner .text', '.js-img-partner .img-content' );
const tabTestimonial = new TabTestimonial('.info-testimonial-partner div', '.js-nav-content span' );

// Acordion FAQ
const accordion = new Accordion('[data-anime="accordion"] dt');


// Modal
const modal = new Modal('[data-modal="abrir"]', '[data-modal="fechar"]', '[data-modal="container"]');
const modalMobile = new ModalMobile('[data-modal-mobile="abrir"]', '[data-modal-mobile="fechar"]', '[data-modal-mobile="container"]');
const modalMobileSolution = new ModalMobileSolution('[data-modal-solution="abrir"]', '[data-modal-solution="fechar"]', '[data-modal-solution="container"]');
const modalWhy = new ModalWhy('[data-modal-why="abrir"]', '[data-modal-why="fechar"]', '[data-modal-why="container"]');
const modalIndustries = new ModalIndustries('[data-modal-industries="abrir"]', '[data-modal-industries="fechar"]', '[data-modal-industries="container"]');
const modalCompany = new ModalCompany('[data-modal-company="abrir"]', '[data-modal-company="fechar"]', '[data-modal-company="container"]');
const modalResources = new ModalResources('[data-modal-resources="abrir"]', '[data-modal-resources="fechar"]', '[data-modal-resources="container"]');
const modalCeo = new ModalCeo('[data-modal-ceo="abrir"]', '[data-modal-ceo="fechar"]', '[data-modal-ceo="container"]');
const modalCoo = new ModalCoo('[data-modal-coo="abrir"]', '[data-modal-coo="fechar"]', '[data-modal-coo="container"]');
const modalCpo = new ModalCpo('[data-modal-cpo="abrir"]', '[data-modal-cpo="fechar"]', '[data-modal-cpo="container"]');
const modalMdp = new ModalMdp('[data-modal-mdp="abrir"]', '[data-modal-mdp="fechar"]', '[data-modal-mdp="container"]');
const modalSdc = new ModalSdc('[data-modal-sdc="abrir"]', '[data-modal-sdc="fechar"]', '[data-modal-sdc="container"]');
const modalDoe = new ModalDoe('[data-modal-doe="abrir"]', '[data-modal-doe="fechar"]', '[data-modal-doe="container"]');
const modalAbout = new ModalAbout('[data-modal-about="abrir"]', '[data-modal-about="fechar"]', '[data-modal-about="container"]');
const modalCookies = new ModalCookies('[data-modal-cookies="abrir"]', '[data-modal-cookies="fechar"]', '[data-modal-cookies="container"]');


modal.init();
modalAbout.init();
modalCookies.init();
modalWhy.init();
modalMdp.init();
modalDoe.init();
modalSdc.init();
modalCeo.init();
modalCpo.init();
modalCoo.init();
modalResources.init();
modalCompany.init();
modalIndustries.init();
modalMobile.init();
modalMobileSolution.init();
tabTestimonial.init();
accordion.init();
tabPartner.init();
tabIntegrations.init();
tabSolving.init();
tabWorkBg.init();
tabWork.init();
tabSolution.init();

// initOutsideClick();

const theme = new ThemeClass(
    config,
    KMDGPB,
    new SlickSlider()
);

$(() => theme.__onReady());
$(window).on('load', () => theme.__onLoad());
$(window).on('resize', () => theme.__onResize());

window.Theme = theme;

Utils.log("Loading Theme...");