import CMP from "./CMP.class";
import Utils from "./utils.class";
const { jQuery: $ } = window;

export default class CookieYes extends CMP {
    #map = {
        functional: "functional",
        analytics: "statistics",
        performance: "preferences",
        advertisement: "marketing",
        necessary: "necessary",
        other: "necessary",
    };

    #reverseMap = {};

    constructor($body, readyCB = ()=>true, debug = false) {

        super($body, readyCB, debug)

        for (let i in this.#map) {
            this.#reverseMap[this.#map[i]] = i;
        }

        $(document).on("cookieyes_consent_update", (consentData) => this.#mapEvents(consentData));
    }

    load() {
        Utils.log("Loading CookieYes Integration.");

        for(let i in this.types) {
            const type = this.types[i];

            if(this.hasConsent(type)) {
                $(window).trigger(this.events.consent[type]);
            } else {
                $(window).trigger(this.events.denied[type]);
            }
        }
    }

    hasConsent(category) {
        if(window.getCkyConsent) {
            const consent = window.getCkyConsent();

            return consent.categories[this.#reverseMap[category]];
        } else {
            return this.debug;
        }
    }

    open() {
        const link = document.createElement("a");
        link.classList.add("cky-banner-element");
        this.$body.append(link);
        $(link).trigger("click").remove();
    }

    #mapEvents(eventData) {
        const data = eventData.detail;
        Utils.log("Consent Update:", eventData);

        for(let i in data.accepted) {
            Utils.log(data.accepted[i]);
            Utils.log(this.#map);
            Utils.log(this.#map[data.accepted[i]]);
            $(window).trigger(this.events.consent[this.#map[data.accepted[i]]]);
        }

        for(let i in data.rejected) {
            $(window).trigger(this.events.denied[this.#map[data.rejected[i]]]);
        }

        for(let i in this.types) {
            const type = this.types[i];

            if(this.hasConsent(type)) {
                $(window).trigger(this.events.consent[type]);
            } else {
                $(window).trigger(this.events.denied[type]);
            }
        }
    }

    #reverseTypeLookup(type) {
        return Object.keys(this.#map).find(key => this.#map[key] === type);
    }
}