export default class modalIndustries {
  constructor(botaoAbrirIndustries, botaoFecharIndustries, containerModalIndustries) {
    this.botaoAbrirIndustries = document.querySelector(botaoAbrirIndustries);
    this.botaoFecharIndustries = document.querySelector(botaoFecharIndustries);
    this.containerModalIndustries = document.querySelector(containerModalIndustries);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalIndustries = this.eventToggleModalIndustries.bind(this);
    this.cliqueForaModalIndustries = this.cliqueForaModalIndustries.bind(this);
  }

  // abre ou fecha o modal
  toggleModalIndustries() {
    this.containerModalIndustries.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalIndustries(event) {
    if (event.target === this.containerModalIndustries) {
      this.toggleModalIndustries();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalIndustries(event) {
    this.toggleModalIndustries();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsIndustries() {
    this.botaoAbrirIndustries.addEventListener('click', this.eventToggleModalIndustries);
    this.botaoFecharIndustries.addEventListener('click', this.eventToggleModalIndustries);
    this.containerModalIndustries.addEventListener('click', this.cliqueForaModalIndustries);
  }

  init() {
    if (this.botaoAbrirIndustries && this.botaoFecharIndustries && this.containerModalIndustries) {
      this.addModalEventsIndustries();
    }
    return this;
  }
}
