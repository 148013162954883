import Utils from "../utils.class";
import $ from "jquery";

export default class TabsLayout {

    #theme;
    #$tabs;
    #updateInterval = 500;

    /**
     * @param {Theme} theme
     */
    constructor(theme) {
        this.#theme = theme;
        this.#$tabs = $('.static-tabs');
        Utils.log("Registered: Tabs Layout");
    }

    init() {
        Utils.equalHeight('.tab_box__js', '.tab_box');

        setInterval(() => this.timer(), this.#updateInterval);
    }

    events() {
        const me = this;

        this.#$tabs.each((i, el) => {
            const $el = $(el);
            const loadedTab = sessionStorage.getItem($el.attr("id"));

            if(loadedTab && loadedTab > 0) {
                setTimeout(() => {
                    this.activate($el.find('.tab_box .single_tab__tab[data-tab="'+loadedTab+'"]'));
                }, 1);
            }
        });

        this.#$tabs.find('.tab_box .single_tab__tab').on('click', function () {
            $(this).parents('.static-tabs').addClass("js--manual");
            me.activate($(this));
        });

        this.#$tabs.find('.tab_box__mobile').on('click', function () {
            const $parent = $('.tab_box');
            $parent.find('.tab_box__tabs').toggleClass("tab_box__tabs--open");
            $parent.find('.tab_box__mobile').toggleClass("tab_box__mobile--open");
        });

        this.#$tabs.find('.tab_box').each(function (i, el) {
            const $tab = $(el).find('.single_tab__overtab:first-child');
            $(el).find('.tab_box__mobile').html($tab.prop('outerHTML'));
            $(el).attr("data-height", $(el).find('.tab_box__two').css("max-height"));
        });
    }

    timer() {
        this.#$tabs.not(".js--manual").each((i, el) => {
            const $el = $(el);
            const timeout = $el.data("timeout");
            const $active = $el.find(".single_tab__overtab.active");
            const $timer = $active.find(".single_tab__timer");
            let progress = $el.data("progress") || 0;

            // Increment progress bar based on % of timeout so that the bar reaches 100% after the correct time has passed
            progress = progress + (timeout / (timeout / this.#updateInterval));

            $el.data("progress", progress);

            // Use CSS animation and move bar by whatever % is needed since last interval
            $timer.css("transition-duration", `${this.#updateInterval}ms`).width((100 * (progress / timeout)) + "%");

            if(progress >= timeout) {
                let num = Number($active.attr('data-tab')) + 1;
                const $next = $el.find(`.single_tab__tab[data-tab='${num}']`);

                if($next.length > 0) {
                    this.activate($next);
                } else {
                    this.activate($el.find(`.single_tab__tab[data-tab='1']`));
                }

                $el.data("progress", 0);
                $timer.css("transition-duration", `0ms`).width("0%");
            }
        });
    }

    activate($el) {
        const num = $el.attr('data-tab');
        const $parent = $el.parents('.static-tabs');
        const $tabs = $parent.find('.tab_box');
        const $content = $parent.find('.tab_box__two');
        const $tab = $el.parents('.single_tab__overtab');
        const $extras = $parent.find('.tab_box__extra');

        sessionStorage.setItem($parent.attr("id"), num);

        $content.find('.single_tab__content').removeClass('active').addClass('off');
        $content.find('[data-tab="' + num + '"]').addClass('active').removeClass('off');

        $tabs.find('.single_tab__overtab').removeClass('active').addClass('off');
        $tab.addClass('active').removeClass('off');

        $extras.find('.single_tab__extra').removeClass('active').addClass('off');
        $extras.find('[data-tab="' + num + '"]').addClass('active').removeClass('off');

        $parent.find('.tab_box__mobile').html($tab.prop('outerHTML'));
        $parent.find('.tab_box__tabs').removeClass('tab_box__tabs--open');
    }
}
