export default class TabWork {
    constructor(info, graphic, link, cta) {
        this.infoWork = document.querySelectorAll(info);
        this.contentWork = document.querySelectorAll(graphic);
        this.linkWork = document.querySelectorAll(link);
        this.ctaWork = document.querySelectorAll(cta);
        this.activeClass = 'ativo';
    }

    // Tab Menu - How Work
    activeInfo(index) {
        this.infoWork.forEach((div) => {
            div.classList.remove(this.activeClass)
        });
        this.infoWork[index].classList.add(this.activeClass)
    }

    activeContent(index) {
        this.contentWork.forEach((div) => {
            div.classList.remove(this.activeClass)
        });
        this.contentWork[index].classList.add(this.activeClass)
    }

    activeLink(index) {
        this.linkWork.forEach((div) => {
            div.classList.remove(this.activeClass)
        });

        this.linkWork[index].classList.add(this.activeClass)
    }

    activeCta(index) {
        this.ctaWork.forEach((div) => {
            div.classList.remove(this.activeClass)
        });
        this.ctaWork[index].classList.add(this.activeClass)
    }

    // Adiciona os eventos nas tabs
    addTabNavEvent() {

        this.linkWork.forEach((cardWork, index) => {
            cardWork.addEventListener('mouseover', () => this.activeLink(index))
        })

        this.linkWork.forEach((cardWork, index) => {
            cardWork.addEventListener('mouseover', () => this.activeContent(index))
        })

        this.linkWork.forEach((cardWork, index) => {
            cardWork.addEventListener('mouseover', () => this.activeInfo(index))
        })

        this.linkWork.forEach((cardWork, index) => {
            cardWork.addEventListener('mouseover', () => this.activeCta(index))
        })
    }

// Inicia os eventos
    init() {
      try {
        this.activeLink(0);
        this.activeContent(0);
        this.activeInfo(0);
        this.activeCta(0);
        this.addTabNavEvent(0);
      } catch (e) {
        console.warn("TabWork Init Failed");
      }
    }
}

