import $ from "jquery";
import Cookies from 'js-cookie';

export default class Utils {

    static #URLSearchParams = new URLSearchParams(window.location.search);

    /**
     * Equalizes the height of a given group of elements, making them all
     * at least as big as the largest of the group (but not restricting future
     * expansion. You may optionally pass in a parent selector by which the
     * elements will be equalized (each instance of the group selector will be
     * given equal heights within their parent, rather than across all elements
     * matching that selector globally).
     * @param {string} group
     * @param {OptionalString} parent
     */
    static equalHeight(group, parent) {
        parent = parent || "body";
        $(parent).each(function () {
            let tallest = 0;
            $(this).find(group).each(function () {
                let $this = $(this);
                let thisHeight = $this.height();

                if ($this.css('box-sizing') === 'content-box') {
                    if (thisHeight > tallest) {
                        tallest = thisHeight;
                    }
                } else {
                    let thisPadding = Number($this.css('padding-top').replace('px', ''))
                        + Number($this.css('padding-bottom').replace('px', ''));

                    if ((thisHeight + thisPadding) > tallest) {
                        tallest = thisHeight + thisPadding;
                    }
                }
            });

            $(this).find(group).css('min-height', tallest);
        });
    }

    /**
     * Animates scrolling to a point on the page, uses the data-scroll attribute rather than the ID attribute to determine
     * the scroll location as this avoids any interactions with the browser's built in hash scrolling.
     *
     * @param {string} hash
     * @param {string} child
     * @param {number} offset
     * @param {number} speed
     * @returns {boolean}
     * @constructor
     */
    static scrollToHash(hash, child, offset, speed) {
        offset = offset || 0;
        speed = speed || 800;

        if(hash.indexOf('/') >= 0) {
            hash = hash.split('/', 1)[0];
        }

        hash = encodeURIComponent(hash.replace('#', ''));

        let element = $('[data-scroll="'+hash+'"]');

        if(child && $(element).find(child).length > 0) {
            element = $(element).find(child);
        }

        if($(element).length > 0) {
            if (!element.hasClass('recent-scroll')) {
                element.addClass('scroll-target').addClass('recent-scroll');
            }

            $('html, body').animate({
                scrollTop: (
                    element.offset().top - offset
                )
            }, speed, 'swing', function() {
                setTimeout(function() {
                    element.removeClass('scroll-target');
                }, 500);

                setTimeout(function() {
                    element.removeClass('recent-scroll');
                }, 5000);
            });
            return true;
        }

        return false;
    }

    /**
     * Removes whitespace from a string.
     * @param {string} x
     * @returns {string}
     */
    static trim (x) {
        return x.replace(/^\s+|\s+$/gm, '');
    }

    /**
     * Used to check an email address against various domains as a blacklist.
     *
     * @param {string[]} invald
     * @param {string} email
     * @returns {boolean}
     */
    static isEmailGood (invald, email) {
        for(let i=0; i < invald.length; i++) {
            let domain = invald[i];
            if (email.indexOf(domain) != -1) {
                return false;
            }
        }
        return true;
    }

    /**
     * This is a wrapper for console.log() which does not produce output unless a debug cookie has been set in the user's
     * browser. This is to keep the console less cluttered but still allow for robust debugging when needed. Use console.log()
     * for messages that should always be logged, and this for more detailed output.
     */
    static log(...args) {
        if(Cookies.get("debug") === "1") {
            console.log(...args);
        }
    }

    /**
     * This function will self-throttle so that the callback is only called once every X milliseconds. Use the immediate flag
     * to let the first run of this to call the callback immediately, else it will wait for the timeout before calling it
     * for the first time.
     *
     * Note: This returns a function, so it can be used directly when creating an event watcher.
     *
     * @param {number} wait
     * @param {boolean} immediate
     * @param {callback} func
     * @returns {function(): void}
     */
    static debounce (wait, immediate, func) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    /**
     * Takes a number value and adds commas to it, ex: 1000000 -> 1,000,000
     *
     * @param {string|number} val
     * @returns {string}
     */
    static commaSeparateNumber (val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
            val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }
        return val;
    }

    /**
     * Parses a URL to determine if it contains a YouTube ID.
     *
     * @param {string} url
     * @returns {string|false}
     */
    static youtubeParser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    static vimeoParser(url){
        var regExp = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/;
        var match = url.match(regExp);
        return (match&&match[5].length==9)? match[5] : false;
    }

    static get URLSearchParams() {
        return this.#URLSearchParams
    }
}
