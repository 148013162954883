export default class TabTestimonial {
  constructor(testimonial, navigation) {
    this.tabNavigation = document.querySelectorAll(navigation);
    this.tabTestimonial = document.querySelectorAll(testimonial);
    this.activeClass = 'ativo';

    console.log(this.tabNavigation)
  }


  // Tab Menu - Solving Leads
  activeContent(index) {
    this.tabTestimonial.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabTestimonial[index].classList.add(this.activeClass);
  }
  activeNav(index) {
    this.tabNavigation.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabNavigation[index].classList.add(this.activeClass);
  } 
    // Adiciona os eventos nas tabs
    addTabNavEvent(){
      this.tabNavigation.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeNav(index))
      })

      this.tabNavigation.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeContent(index))
      })
    }

// Inicia os eventos
    init() { 
      if (this.tabNavigation.length && this.tabTestimonial.length) {
        this.activeNav(0);
        this.activeContent(0);
        this.addTabNavEvent(0);
      }
    }
  }

