export default class TabSolving {
  constructor(menu, content) {
    this.tabMenu = document.querySelectorAll(menu);
    this.tabContent = document.querySelectorAll(content);
    this.activeClass = 'ativo';
  }


  // Tab Menu - Solving Leads
  activeTab(index) {
    this.tabContent.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabContent[index].classList.add(this.activeClass);
  }
  activeMenu(index) {
    this.tabMenu.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabMenu[index].classList.add(this.activeClass);
  } 
    // Adiciona os eventos nas tabs
    addTabNavEvent(){
      this.tabMenu.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeTab(index))
      })

      this.tabMenu.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeMenu(index))
      })
    }

// Inicia os eventos
    init() { 
      if (this.tabMenu.length && this.tabContent.length) {
        this.activeTab(0);
        this.activeMenu(0);
        this.addTabNavEvent(0);
      }
    }
  }

