export default class TabPartner {
  constructor(text, imagem) {
    this.tabText = document.querySelectorAll(text);
    this.tabImagem = document.querySelectorAll(imagem);
    this.activeClass = 'ativo';
  }


  // Tab Menu - Solving Leads
  activeImagem(index) {
    this.tabImagem.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabImagem[index].classList.add(this.activeClass);
  }
  activeText(index) {
    this.tabText.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.tabText[index].classList.add(this.activeClass);
  } 
    // Adiciona os eventos nas tabs
    addTabNavEvent(){
      this.tabText.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeText(index))
      })

      this.tabText.forEach((itemMenu, index) => {
        itemMenu.addEventListener('mouseover', () => this.activeImagem(index))
      })
    }

// Inicia os eventos
    init() { 
      if (this.tabText.length && this.tabImagem.length) {
        this.activeText(0);
        this.activeImagem(0);
        this.addTabNavEvent(0);
      }
    }
  }

