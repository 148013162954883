export default class modalCeo {
  constructor(botaoAbrirCeo, botaoFecharCeo, containerModalCeo) {
    this.botaoAbrirCeo = document.querySelector(botaoAbrirCeo);
    this.botaoFecharCeo = document.querySelector(botaoFecharCeo);
    this.containerModalCeo = document.querySelector(containerModalCeo);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalCeo = this.eventToggleModalCeo.bind(this);
    this.cliqueForaModalCeo = this.cliqueForaModalCeo.bind(this);
  }

  // abre ou fecha o modal
  toggleModalCeo() {
    this.containerModalCeo.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalCeo(event) {
    if (event.target === this.containerModalCeo) {
      this.toggleModalCeo();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalCeo(event) {
    this.toggleModalCeo();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsCeo() {
    this.botaoAbrirCeo.addEventListener('click', this.eventToggleModalCeo);
    this.botaoFecharCeo.addEventListener('click', this.eventToggleModalCeo);
    this.containerModalCeo.addEventListener('click', this.cliqueForaModalCeo);
  }

  init() {
    if (this.botaoAbrirCeo && this.botaoFecharCeo && this.containerModalCeo) {
      this.addModalEventsCeo();
    }
    return this;
  }
}
