export default class modalAbout {
  constructor(botaoAbrirAbout, botaoFecharAbout, containerModalAbout) {
    this.botaoAbrirAbout = document.querySelector(botaoAbrirAbout);
    this.botaoFecharAbout = document.querySelector(botaoFecharAbout);
    this.containerModalAbout = document.querySelector(containerModalAbout);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalAbout = this.eventToggleModalAbout.bind(this);
    this.cliqueForaModalAbout = this.cliqueForaModalAbout.bind(this);
  }

  // abre ou fecha o modal
  toggleModalAbout() {
    this.containerModalAbout.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalAbout(event) {
    if (event.target === this.containerModalAbout) {
      this.toggleModalAbout();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalAbout(event) {
    this.toggleModalAbout();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsAbout() {
    this.botaoAbrirAbout.addEventListener('click', this.eventToggleModalAbout);
    this.botaoFecharAbout.addEventListener('click', this.eventToggleModalAbout);
    this.containerModalAbout.addEventListener('click', this.cliqueForaModalAbout);
  }

  init() {
    if (this.botaoAbrirAbout && this.botaoFecharAbout && this.containerModalAbout) {
      this.addModalEventsAbout();
    }
    return this;
  }
}
