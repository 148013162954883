export default class modalSdc {
  constructor(botaoAbrirSdc, botaoFecharSdc, containerModalSdc) {
    this.botaoAbrirSdc = document.querySelector(botaoAbrirSdc);
    this.botaoFecharSdc = document.querySelector(botaoFecharSdc);
    this.containerModalSdc = document.querySelector(containerModalSdc);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalSdc = this.eventToggleModalSdc.bind(this);
    this.cliqueForaModalSdc = this.cliqueForaModalSdc.bind(this);
  }

  // abre ou fecha o modal
  toggleModalSdc() {
    this.containerModalSdc.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalSdc(event) {
    if (event.target === this.containerModalSdc) {
      this.toggleModalSdc();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalSdc(event) {
    this.toggleModalSdc();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsSdc() {
    this.botaoAbrirSdc.addEventListener('click', this.eventToggleModalSdc);
    this.botaoFecharSdc.addEventListener('click', this.eventToggleModalSdc);
    this.containerModalSdc.addEventListener('click', this.cliqueForaModalSdc);
  }

  init() {
    if (this.botaoAbrirSdc && this.botaoFecharSdc && this.containerModalSdc) {
      this.addModalEventsSdc();
    }

    return this;
  }
}
