import $ from "jquery";
import Cookies from 'js-cookie';
import Utils from "./utils.class";
import Featherlight from "./../lib/featherlight";
import TabsLayout from "./layouts/tabs";
import PeopleGrid from "./layouts/people_grid";
import Hubspot from "./components/Hubspot.class";
import CookieYes from "./CookieYes.class";

export default class ThemeClass {
    #html;
    #body;
    #builder;
    #slider;
    #scrollOffset;
    #scrollSpeed;
    #header;
    #counter;
    #TabsLayout;
    #admin;
    #root;
    #PeopleGrid;
    #hubspot;
    #cmp;

    constructor(
        config,
        builder,
        slider
    ) {
        this.#builder = builder;
        this.#admin = $('#wpadminbar');
        this.#slider = slider;

        this.#html = $('html');
        this.#body = $('body');
        this.#root = document.querySelector(":root");

        this.#cmp = new CookieYes(this.#body, function() {
            return typeof window.getCkyConsent === 'function';
        }, !(config.env === 'production' || (config.env !== 'production' && Cookies.get("debug") == 1)));

        this.#hubspot = new Hubspot("5134642", this.#cmp);

        this.#scrollOffset = -50;
        this.#scrollSpeed = 750;

        this.#counter = $('.counter__number');
        this.#TabsLayout = new TabsLayout(this);

        this.#header = $('header.site_header');
        this.#PeopleGrid = new PeopleGrid(this);

        if (window.location.hash == "#dev") {
            Cookies.set("debug", 1);
        }
    }

    __onReady() {
        const me = this;
        Utils.log("Theme Ready");

        this.#slider.setup();
        me.#TabsLayout.events();
        me.#PeopleGrid.events();

        $(window).on('hashchange', function (e) {
            Utils.log("Scroll to Section Ready");

            if (location.hash) {
                if (!Utils.scrollToHash(location.hash, null, me.scrollOffset, me.#scrollSpeed)) {
                    e.preventDefault();
                }
            }
        });

        $('a').on('click', function (e) {
            let $el = $(this);

            if ($el.attr('href') && $el.attr('href').search('#') === 0) {
                let hash = $el[0].hash;

                if (!Utils.scrollToHash(hash, null, me.scrollOffset, me.#scrollSpeed)) {
                    e.preventDefault();
                }
            }
        });


    }

    __onLoad() {
        const me = this;
        Utils.log("Theme Load");
        this.#admin = $('#wpadminbar');

        this.#TabsLayout.init();

        this.#slider.show();

        // Utils.equalHeight('.quoteSlider__box', '.quoteSlider');

        $('.single_accordion .single_accordion__header').on('click', function () {
            var $column = $(this).parent();

            $column.toggleClass('open');
            $column.find('.single_accordion__inner').slideToggle({
                duration: 250,
                progress: function () {
                    $(window).trigger('resize');
                }
            });
        });

        function counter(el) {
            let $me = $(el),
                time = ($me.attr('data-time') || 3) * 1000,
                countTo = $me.attr('data-count'),
                delay = ($me.attr('data-delay') || 0) * 1000;

            setTimeout(function () {
                $({
                    countNum: 0
                }).animate({
                        countNum: countTo
                    },

                    {
                        duration: time,
                        easing: 'linear',
                        step: function () {
                            $me.text(Utils.commaSeparateNumber(Math.floor(this.countNum)));
                        },
                        complete: function () {
                            $me.text(Utils.commaSeparateNumber(this.countNum));
                        }
                    }
                );
            }, delay);
        }

        this.#counter.each(function (i, el) {

            $(el).on('animationTrigger', function () {
                counter(el);
            });

            $(el).data('waypoint', new Waypoint.Inview({
                element: el,
                entered: function (dir) {
                    if ($(el).parents('.animation-trigger').length == 0) {
                        counter(el);
                    }
                },
                exited: function (dir) {

                }
            }));
        });

        this.#header.addClass('fixed');

        this.#updateCssHeaderHeight();

    }

    get utils() {
        return Utils;
    }

    __onResize() {
        this.#updateCssHeaderHeight();
    }

    get headerHeight() {
        if (this.#admin.length > 0) {
            return this.#header.outerHeight() + this.#admin.outerHeight();
        }

        return this.#header.outerHeight();
    }

    #updateCssHeaderHeight() {
        this.#root.style.setProperty('--header-height', this.headerHeight + "px");
    }

    get CMP() {
        return this.#cmp;
    }
}