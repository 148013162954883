export default class modalCookies {
  constructor(botaoAbrirCookies, botaoFecharCookies, containerModalCookies) {
    this.botaoAbrirCookies = document.querySelector(botaoAbrirCookies);
    this.botaoFecharCookies = document.querySelector(botaoFecharCookies);
    this.containerModalCookies = document.querySelector(containerModalCookies);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalCookies = this.eventToggleModalCookies.bind(this);
    this.cliqueForaModalCookies = this.cliqueForaModalCookies.bind(this);
  }

  // abre ou fecha o modal
  toggleModalCookies() {
    this.containerModalCookies.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalCookies(event) {
    if (event.target === this.containerModalCookies) {
      this.toggleModalCookies();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalCookies(event) {
    this.toggleModalCookies();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsCookies() {
    this.botaoAbrirCookies.addEventListener('click', this.eventToggleModalCookies);
    this.botaoFecharCookies.addEventListener('click', this.eventToggleModalCookies);
    this.containerModalCookies.addEventListener('click', this.cliqueForaModalCookies);
  }

  init() {
    if (this.botaoAbrirCookies && this.botaoFecharCookies && this.containerModalCookies) {
      this.addModalEventsCookies();
    }
    return this;
  }
}
