const {jQuery: $} = window;

import Utils from "../utils.class";

export default class PeopleGrid {

    #theme;

    /**
     * @param {Theme} theme
     */
    constructor(theme) {
        this.#theme = theme;

        Utils.log("Registered: PeopleGrid Layout");
    }

    events() {
        const me = this;

        $('.single_person').on('click', function () {
            const $this = $(this);
            const $parent = $this.parents('.people_grid');
            const num = $this.attr('data-id');
            const $desc = $parent.find('.sp_description[data-id="'+num+'"]');

            Utils.log("Toggling description:", num);

            if(!$this.is(".js-open")) {
                $parent.find('.sp_description, .single_person').removeClass('js-open');

                $this.addClass('js-open');
                $desc.addClass('js-open').hide().slideDown({
                    duration: 250
                });
            } else {
                $parent.find('.sp_description, .single_person').removeClass('js-open');
            }
        });


    }

}
