export default class modalResources {
  constructor(botaoAbrirResources, botaoFecharResources, containerModalResources) {
    this.botaoAbrirResources = document.querySelector(botaoAbrirResources);
    this.botaoFecharResources = document.querySelector(botaoFecharResources);
    this.containerModalResources = document.querySelector(containerModalResources);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalResources = this.eventToggleModalResources.bind(this);
    this.cliqueForaModalResources = this.cliqueForaModalResources.bind(this);
  }

  // abre ou fecha o modal
  toggleModalResources() {
    this.containerModalResources.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalResources(event) {
    if (event.target === this.containerModalResources) {
      this.toggleModalResources();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalResources(event) {
    this.toggleModalResources();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsResources() {
    this.botaoAbrirResources.addEventListener('click', this.eventToggleModalResources);
    this.botaoFecharResources.addEventListener('click', this.eventToggleModalResources);
    this.containerModalResources.addEventListener('click', this.cliqueForaModalResources);
  }

  init() {
    if (this.botaoAbrirResources && this.botaoFecharResources && this.containerModalResources) {
      this.addModalEventsResources();
    }
    return this;
  }
}
