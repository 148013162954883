export default class modalDoe {
  constructor(botaoAbrirDoe, botaoFecharDoe, containerModalDoe) {
    this.botaoAbrirDoe = document.querySelector(botaoAbrirDoe);
    this.botaoFecharDoe = document.querySelector(botaoFecharDoe);
    this.containerModalDoe = document.querySelector(containerModalDoe);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalDoe = this.eventToggleModalDoe.bind(this);
    this.cliqueForaModalDoe = this.cliqueForaModalDoe.bind(this);
  }

  // abre ou fecha o modal
  toggleModalDoe() {
    this.containerModalDoe.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalDoe(event) {
    if (event.target === this.containerModalDoe) {
      this.toggleModalDoe();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalDoe(event) {
    this.toggleModalDoe();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsDoe() {
    this.botaoAbrirDoe.addEventListener('click', this.eventToggleModalDoe);
    this.botaoFecharDoe.addEventListener('click', this.eventToggleModalDoe);
    this.containerModalDoe.addEventListener('click', this.cliqueForaModalDoe);
  }

  init() {
    if (this.botaoAbrirDoe && this.botaoFecharDoe && this.containerModalDoe) {
      this.addModalEventsDoe();
    }

    return this;
  }
}
