export default class modalWhy {
  constructor(botaoAbrirWhy, botaoFecharWhy, containerModalWhy) {
    this.botaoAbrirWhy = document.querySelector(botaoAbrirWhy);
    this.botaoFecharWhy = document.querySelector(botaoFecharWhy);
    this.containerModalWhy = document.querySelector(containerModalWhy);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalWhy = this.eventToggleModalWhy.bind(this);
    this.cliqueForaModalWhy = this.cliqueForaModalWhy.bind(this);
  }

  // abre ou fecha o modal
  toggleModalWhy() {
    this.containerModalWhy.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalWhy(event) {
    if (event.target === this.containerModalWhy) {
      this.toggleModalWhy();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalWhy(event) {
    this.toggleModalWhy();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsWhy() {
    this.botaoAbrirWhy.addEventListener('click', this.eventToggleModalWhy);
    this.botaoFecharWhy.addEventListener('click', this.eventToggleModalWhy);
    this.containerModalWhy.addEventListener('click', this.cliqueForaModalWhy);
  }

  init() {
    if (this.botaoAbrirWhy && this.botaoFecharWhy && this.containerModalWhy) {
      this.addModalEventsWhy();
    }
    return this;
  }
}
