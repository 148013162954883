export default class TabSolution {
  constructor(image, text) {
    this.imgContent = document.querySelectorAll(image);
    this.cardContent = document.querySelectorAll(text);
    this.activeClass = 'ativo';
  }

//  Tab Menu - Our Solutions
    activeImg(index) {
    this.imgContent.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.imgContent[index].classList.add(this.activeClass)
  }
  
    activeCard(index) {
    this.cardContent.forEach((div) =>{
      div.classList.remove(this.activeClass)
    });
    this.cardContent[index].classList.add(this.activeClass)
  }
    // Adiciona os eventos nas tabs
    addTabNavEvent(){

      this.cardContent.forEach((cardMenu, index) => {
        cardMenu.addEventListener('mouseover', () => this.activeCard(index))
      })

      this.cardContent.forEach((cardMenu, index) => {
        cardMenu.addEventListener('mouseover', () => this.activeImg(index))
      })
    }

// Inicia os eventos
    init() {
      if (this.imgContent.length && this.cardContent.length) {
        this.activeCard(0);
        this.activeImg(0);
        this.addTabNavEvent(0);
      }
    }
  }

