export default class modalMdp {
  constructor(botaoAbrirMdp, botaoFecharMdp, containerModalMdp) {
    this.botaoAbrirMdp = document.querySelector(botaoAbrirMdp);
    this.botaoFecharMdp = document.querySelector(botaoFecharMdp);
    this.containerModalMdp = document.querySelector(containerModalMdp);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalMdp = this.eventToggleModalMdp.bind(this);
    this.cliqueForaModalMdp = this.cliqueForaModalMdp.bind(this);
  }

  // abre ou fecha o modal
  toggleModalMdp() {
    this.containerModalMdp.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalMdp(event) {
    if (event.target === this.containerModalMdp) {
      this.toggleModalMdp();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalMdp(event) {
    this.toggleModalMdp();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsMdp() {
    this.botaoAbrirMdp.addEventListener('click', this.eventToggleModalMdp);
    this.botaoFecharMdp.addEventListener('click', this.eventToggleModalMdp);
    this.containerModalMdp.addEventListener('click', this.cliqueForaModalMdp);
  }

  init() {
    if (this.botaoAbrirMdp && this.botaoFecharMdp && this.containerModalMdp) {
      this.addModalEventsMdp();
    }
    return this;
  }
}
