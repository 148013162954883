export default class modalCpo {
  constructor(botaoAbrirCpo, botaoFecharCpo, containerModalCpo) {
    this.botaoAbrirCpo = document.querySelector(botaoAbrirCpo);
    this.botaoFecharCpo = document.querySelector(botaoFecharCpo);
    this.containerModalCpo = document.querySelector(containerModalCpo);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalCpo = this.eventToggleModalCpo.bind(this);
    this.cliqueForaModalCpo = this.cliqueForaModalCpo.bind(this);
  }

  // abre ou fecha o modal
  toggleModalCpo() {
    this.containerModalCpo.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalCpo(event) {
    if (event.target === this.containerModalCpo) {
      this.toggleModalCpo();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalCpo(event) {
    this.toggleModalCpo();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsCpo() {
    this.botaoAbrirCpo.addEventListener('click', this.eventToggleModalCpo);
    this.botaoFecharCpo.addEventListener('click', this.eventToggleModalCpo);
    this.containerModalCpo.addEventListener('click', this.cliqueForaModalCpo);
  }

  init() {
    if (this.botaoAbrirCpo && this.botaoFecharCpo && this.containerModalCpo) {
      this.addModalEventsCpo();
    }
    
    return this;
  }
}
