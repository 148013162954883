export default class modalCompany {
  constructor(botaoAbrirCompany, botaoFecharCompany, containerModalCompany) {
    this.botaoAbrirCompany = document.querySelector(botaoAbrirCompany);
    this.botaoFecharCompany = document.querySelector(botaoFecharCompany);
    this.containerModalCompany = document.querySelector(containerModalCompany);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalCompany = this.eventToggleModalCompany.bind(this);
    this.cliqueForaModalCompany = this.cliqueForaModalCompany.bind(this);
  }

  // abre ou fecha o modal
  toggleModalCompany() {
    this.containerModalCompany.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalCompany(event) {
    if (event.target === this.containerModalCompany) {
      this.toggleModalCompany();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalCompany(event) {
    this.toggleModalCompany();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsCompany() {
    this.botaoAbrirCompany.addEventListener('click', this.eventToggleModalCompany);
    this.botaoFecharCompany.addEventListener('click', this.eventToggleModalCompany);
    this.containerModalCompany.addEventListener('click', this.cliqueForaModalCompany);
  }

  init() {
    if (this.botaoAbrirCompany && this.botaoFecharCompany && this.containerModalCompany) {
      this.addModalEventsCompany();
    }
    return this;
  }
}
