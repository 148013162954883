import $ from 'jquery';
import Utils from "./utils.class";
export default class SlickSlider {
    #$carousel;
    #$inlineBlockSlider;
    #$carouselSpotlight;
    #$quoteSlider;

    constructor() {
        Utils.log("Slider Initialized");
        this.#$carousel = $(".slick-block-slider");
        this.#$inlineBlockSlider = $(".inlineBlockSlider");
        this.#$carouselSpotlight = $(".carouselSpotlight");
        this.#$quoteSlider = $(".quoteSlider");

    }

    setup() {
        Utils.log("Slick Initialized");
        this.#$inlineBlockSlider.each(function() {
            const arrowToggle = $(this).data("arrows");
            const dotToggle = $(this).data("dots");
            const speed = $(this).data("speed");
            const mobileSpeed = $(this).data("mobile-speed");
            const rightArrow  = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ff0000}</style><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>`;
            const leftArrow = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ff0000}</style><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>`;

            $(this).slick({
                arrows: arrowToggle,
                prevArrow: `<button type="button" class="slick-prev slick-custom-prev"> ${leftArrow} </button>`,
                nextArrow: `<button type="button" class="slick-next slick-custom-next"> ${rightArrow} </button>`,
                autoplay: true,
                autoplaySpeed: speed,
                pauseOnFocus: true,
                pauseOnHover: true,
                autoHeight: "calc",
                dots: dotToggle,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 650,
                        settings: {
                            autoplaySpeed: mobileSpeed,
                        }
                    }
                ]
            });
        })
        this.#$carouselSpotlight.each(function() {
            const arrowToggle = $(this).data("arrows");
            const dotToggle = $(this).data("dots");
            const speed = $(this).data("speed");
            const cycle = $(this).data("cycle");
            const mobileSpeed = $(this).data("mobile-speed");
            const rightArrow  = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ff0000}</style><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>`;
            const leftArrow = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ff0000}</style><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>`;

            $(this).slick({
                arrows: arrowToggle,
                prevArrow: `<button type="button" class="slick-prev slick-custom-prev"> ${leftArrow} </button>`,
                nextArrow: `<button type="button" class="slick-next slick-custom-next"> ${rightArrow} </button>`,
                autoplay: cycle,
                autoplaySpeed: speed,
                pauseOnFocus: true,
                pauseOnHover: true,
                autoHeight: "calc",
                dots: dotToggle,
                adaptiveHeight: true,
                // variableWidth: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 786,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplaySpeed: mobileSpeed,
                        }
                    },
                    {
                        breakpoint: 486,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: mobileSpeed,
                        }
                    }
                ]
            });
        })
        this.#$quoteSlider.each(function() {
            const dotToggle = $(this).data("dots");
            const speed = $(this).data("speed");
            const cycle = $(this).data("cycle");
            const mobileSpeed = $(this).data("mobile-speed");

            $(this).slick({
                adaptiveHeight: true,
                appendDots: $(".quoteSlider"),
                arrows: false,
                autoplay: cycle,
                autoplaySpeed: speed,
                centerMode: true,
                centerPadding: '60px',
                cssEase: 'linear',
                dots: dotToggle,
                infinite: true,
                pauseOnFocus: true,
                pauseOnHover: true,
                pauseOnDotsHover: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 786,
                        settings: {
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplaySpeed: mobileSpeed,
                        }
                    },
                    {
                        breakpoint: 486,
                        settings: {
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: mobileSpeed,
                        }
                    }
                ]

            });
        })
        this.#$carousel.each(function() {
            $(this).slick();
        })
    }

    show() {
        Utils.log("Slider loaded");

    }
}