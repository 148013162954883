export default class modalMobile {
  constructor(botaoAbrirMobile, botaoFecharMobile, containerModalMobile) {
    this.botaoAbrirMobile = document.querySelector(botaoAbrirMobile);
    this.botaoFecharMobile = document.querySelector(botaoFecharMobile);
    this.containerModalMobile = document.querySelector(containerModalMobile);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalMobile = this.eventToggleModalMobile.bind(this);
    this.cliqueForaModalMobile = this.cliqueForaModalMobile.bind(this);
  }

  // abre ou fecha o modal
  toggleModalMobile() {
    this.containerModalMobile.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalMobile(event) {
    if (event.target === this.containerModalMobile) {
      this.toggleModalMobile();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalMobile(event) {
    this.toggleModalMobile();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsMobile() {
    this.botaoAbrirMobile.addEventListener('click', this.eventToggleModalMobile);
    this.botaoFecharMobile.addEventListener('click', this.eventToggleModalMobile);
    this.containerModalMobile.addEventListener('click', this.cliqueForaModalMobile);
  }

  init() {
    if (this.botaoAbrirMobile && this.botaoFecharMobile && this.containerModalMobile) {
      this.addModalEventsMobile();
    }
    return this;
  }
}
