import Utils from "../utils.class";
const { jQuery: $ } = window;

export default class Hubspot {
    #loaded = false;
    #id = null;
    #cmp = null;
    #blockedDomains = [];
    freeDomains = [];

    constructor(id, cmp = null, blockedDomains = [], freeDomains = []) {
        Utils.log("Registered: Hubspot (" + id + ")");

        this.#id = id;
        this.#cmp = cmp;
        this.#blockedDomains = blockedDomains;
        this.freeDomains = freeDomains;

        $(window).on("load", function () {
            setTimeout(function () {
                $(".js-hubspot-hide:not(.js-hidden)").show();
            }, 3000);
        });

        $(window).one("kmdg_crm_ready", () => {
            $(".js-hubspot-hide").addClass("js-hidden");
        });

        // CMP Integration
        if(this.#cmp) {
            $(window).one(this.#cmp.events.consent.marketing, () => this.load());
        } else {
            $(window).on("load", () => this.load());
        }
    }

    load() {
        // Async load forms

        Utils.log("Loading Hubspot...");

        if (!this.#loaded) {
            this.#loaded = true;

            $.getScript("//js.hsforms.net/forms/embed/v2.js", () => {
                Utils.log("Hubspot Fully loaded.");
                $(window).trigger("kmdg_crm_ready", [this]);
            });
        }
    }

    isBlockedDomain(email) {
        return !Utils.isEmailGood(this.#blockedDomains, email);
    }

    handleBadEmail(event, $form) {
        event.preventDefault();
        Utils.log("Blacklisted email.");
        $form.find("input[type='email']").val("");
        $form.html("Thank you, we'll be in touch shortly.");
    }

    get id() {
        return this.#id;
    }
}