export default class modalCoo {
  constructor(botaoAbrirCoo, botaoFecharCoo, containerModalCoo) {
    this.botaoAbrirCoo = document.querySelector(botaoAbrirCoo);
    this.botaoFecharCoo = document.querySelector(botaoFecharCoo);
    this.containerModalCoo = document.querySelector(containerModalCoo);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalCoo = this.eventToggleModalCoo.bind(this);
    this.cliqueForaModalCoo = this.cliqueForaModalCoo.bind(this);
  }

  // abre ou fecha o modal
  toggleModalCoo() {
    this.containerModalCoo.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalCoo(event) {
    if (event.target === this.containerModalCoo) {
      this.toggleModalCoo();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalCoo(event) {
    this.toggleModalCoo();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventsCoo() {
    this.botaoAbrirCoo.addEventListener('click', this.eventToggleModalCoo);
    this.botaoFecharCoo.addEventListener('click', this.eventToggleModalCoo);
    this.containerModalCoo.addEventListener('click', this.cliqueForaModalCoo);
  }

  init() {
    if (this.botaoAbrirCoo && this.botaoFecharCoo && this.containerModalCoo) {
      this.addModalEventsCoo();
    }
    return this;
  }
}
