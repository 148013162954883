export default class modalMobileSolution {
  constructor(botaoAbrirSolution, botaoFecharSolution, containerModalSolution) {
    this.botaoAbrirSolution = document.querySelector(botaoAbrirSolution);
    this.botaoFecharSolution = document.querySelector(botaoFecharSolution);
    this.containerModalSolution = document.querySelector(containerModalSolution);

    // bind this ao callback para
    // fazer referência ao objeto
    // da classe
    this.eventToggleModalSolution = this.eventToggleModalSolution.bind(this);
    this.cliqueForaModalSolution = this.cliqueForaModalSolution.bind(this);
  }

  // abre ou fecha o modal
  toggleModalSolution() {
    this.containerModalSolution.classList.toggle('ativo');
  }

  // adiciona o evento de toggle ao modal
  cliqueForaModalSolution(event) {
    if (event.target === this.containerModalSolution) {
      this.toggleModalSolution();
    }
  }

  // fecha o modal ao clicar do lado de fora
  eventToggleModalSolution(event) {
    this.toggleModalSolution();
  }

  // adiciona os eventos aos elementos do modal
  addModalEventSolution() {
    this.botaoAbrirSolution.addEventListener('click', this.eventToggleModalSolution);
    this.botaoFecharSolution.addEventListener('click', this.eventToggleModalSolution);
    this.containerModalSolution.addEventListener('click', this.cliqueForaModalSolution);
  }

  init() {
    if (this.botaoAbrirSolution && this.botaoFecharSolution && this.containerModalSolution) {
      this.addModalEventSolution();
    }

    this.addModalEventSolution();
    return this;
  }
}
